import React from 'react'
import '../styles/globals.css';
import 'prismjs/themes/prism-tomorrow.css';
import { Mukta } from '@next/font/google'
import { Tinos } from '@next/font/google'
import { Cabin } from '@next/font/google'
import { Bevan } from '@next/font/google'
import { Overlock_SC } from '@next/font/google'
import { Orbitron } from '@next/font/google'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const mutka = Mukta({
  subsets:['latin'],
  variable:'--font-mukta',
  weight:['200','300','400','500','600','700','800'],
  preload:true
})

const cabin = Cabin({
  subsets:['latin'],
  variable:'--font-cabin',
  weight:['400','500','600','700'],
  preload:true
})

const tinos = Tinos({
  subsets:['latin'],
  variable:'--font-tinos',
  weight:['400','700'],
  preload:true
})
const bevan = Bevan({
  subsets:['latin'],
  variable:'--font-bevan',
  weight:'400',
  preload:true
})
const overlock = Overlock_SC({
  subsets:['latin'],
  variable:'--font-overlock',
  weight:'400',
  preload:true
})
const orbitron = Orbitron({
  subsets:['latin'],
  variable:'--font-orbitron',
  weight:['400','500','600','700','800','900'],
  preload:true
})

function MyApp({ Component, pageProps }) {
  return (
    <main className={`${mutka.variable} ${tinos.variable} ${cabin.variable} ${bevan.variable} ${overlock.variable} ${orbitron.variable}`}>
      <span className="theme-sunset" />
      <Component {...pageProps} />
    </main>
  );
}

export default MyApp;
